import React, {useMemo} from 'react';
import {graphql, withPrefix} from 'gatsby';
import {Helmet} from 'react-helmet';
import {GatsbyImage} from 'gatsby-plugin-image';
import {Trans} from 'gatsby-plugin-react-i18next';
import Layout from '../Layout/index.js';
import SEO from '../seo';
import Related from '../relatedPost';
import ParseHTML from '../ParseHTML';
import SubsCard from '../BlogLandingPage/SubsCard';
import useScript from '../../hooks/use-script';
import useCurrentContent, {
  useAuthorDescription,
  useCurrentCategories,
  useCurrentTitle,
  useRelatedPosts,
} from '../../hooks/use-current-content';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
} from 'react-share';

const blogPage = ({data, pageContext}) => {
  let node = [];
  useScript(withPrefix('static/main-blog.js?v=0.68'));
  useScript(withPrefix('static/recaptcha.js?v=0.06'));
  useScript(withPrefix('static/customerIO.js?v=0.01'));

  if (data.post.edges.length > 0) {
    node = data.post.edges[0].node;
  }
  if (data.article.edges.length > 0) {
    node = data.article.edges[0].node;
  }
  if (data.faq.edges.length > 0) {
    node = data.faq.edges[0].node;
  }

  const {
    databaseId,
    content,
    title,
    seo,
    author,
    dateGmt,
    modifiedGmt,
    comments,
    locale,
    translated,
    tableOfContent,
  } = node;
  const {languageMapping, language} = pageContext;

  const getContent = useCurrentContent([translated, content]);
  const getTitle = useCurrentTitle([translated, title]);
  const getDescription = useAuthorDescription(language, author);
  var categories = false;
  var categoriesUri = false;
  var relatedPost = false;
  var featuredImage = false;

  if (node.featuredImage) {
    featuredImage = node.featuredImage;
  }

  if (node.translatedCategoryName) {
    categories = node.translatedCategoryName;
  }

  if (node.translatedCategoryUri) {
    categoriesUri = node.translatedCategoryUri;
  }

  if (node.relatedPost) {
    relatedPost = node.relatedPost;
    relatedPost.forEach(function (related, index) {
      if (relatedPost[index].categories) {
        relatedPost[index].categories = relatedPost[index].categories.nodes;
      }
    });
  } else if (node.relatedArticle) {
    relatedPost = node.relatedArticle;
    relatedPost.forEach(function (related, index) {
      if (relatedPost[index].categories) {
        relatedPost[index].categories = relatedPost[index].categories.nodes;
      }
    });
  } else if (node.relatedFaq) {
    relatedPost = node.relatedFaq;
    relatedPost.forEach(function (related, index) {
      if (relatedPost[index].categories) {
        relatedPost[index].categories = relatedPost[index].categories.nodes;
      }
    });
  }

  const getRelatedPosts = useRelatedPosts([translated, relatedPost]);
  const {category, categoryUri} = useCurrentCategories([
    translated,
    categories,
    categoriesUri,
  ]);

  var databaseIdGlobal = 'window.dId = '.concat(databaseId).concat(';');

  if (seo.opengraphPublishedTime) {
    var publishedDateRaw = seo.opengraphPublishedTime;
    var modifiedDateRaw = seo.opengraphModifiedTime;
  } else {
    publishedDateRaw = dateGmt;
    modifiedDateRaw = modifiedGmt;
  }
  var publishedDate = new Date(publishedDateRaw).toLocaleString(language, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
  var modifiedDate = new Date(modifiedDateRaw).toLocaleString(language, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  var readTime = Math.ceil(content.split(' ').length / 400);
  var commentsOrdered = [];
  comments.nodes.forEach(function (comment) {
    if (commentsOrdered[comment.parentDatabaseId]) {
      commentsOrdered[comment.parentDatabaseId].push(comment);
    } else {
      commentsOrdered[comment.parentDatabaseId] = [comment];
    }
  });

  var blogType = 'blog';
  if (node.internal.type && node.internal.type == 'WpArticle') {
    blogType = 'article';
  }
  if (node.internal.type && node.internal.type == 'WpFaq') {
    blogType = 'faq';
  }

  const canonical = useMemo(() => {
    if (seo.canonical) {
      const urls = {
        development: 'https://dev.dacast.com',
        production: 'https://www.dacast.com',
      };
      if (!seo.canonical.includes(urls.production)) {
        return urls[process.env.NODE_ENV].concat(
          pageContext.language === 'en'
            ? seo.canonical
            : `/${pageContext.language}${seo.canonical}`
        );
      } else {
        return seo.canonical;
      }
    }
    return 'https://www.dacast.com';
  }, [seo]);

  const authorInfo = useMemo(() => {
    const linkedIn = author?.node?.seo?.social?.linkedIn
      ? author?.node?.seo?.social?.linkedIn
      : 'https://www.linkedin.com/company/dacast/';
    const facebook = author?.node?.seo?.social?.facebook
      ? author?.node?.seo?.social?.facebook
      : 'https://www.facebook.com/DacastStreaming/';
    const twitter = author?.node?.seo?.social?.twitter
      ? author?.node?.seo?.social?.twitter
      : 'https://twitter.com/DaCastStreaming';
    const youtube = author?.node?.seo?.social?.youtube
      ? author?.node?.seo?.social?.youtube
      : 'https://www.youtube.com/dacast';
    const authorUrl = author?.node?.url
      ? author?.node?.url
      : 'https://www.dacast.com';
    const authorName = author?.node?.name
      ? author?.node?.name
      : 'Leslie Alexander';
    const authorAvatar = author?.node?.avatar?.url
      ? author?.node?.avatar?.url
      : 'https://www.dacast.com/wp-content/uploads/2012/02/admin_avatar.jpeg';

    return {
      linkedIn,
      facebook,
      twitter,
      youtube,
      authorUrl,
      authorName,
      authorAvatar,
    };
  }, [author]);

  const {
    linkedIn,
    facebook,
    twitter,
    youtube,
    authorUrl,
    authorName,
    authorAvatar,
  } = authorInfo;

  const locales = useMemo(() => {
    if (translated) {
      [
        ...translated.map(({locale}) => ({
          locale: languageMapping[locale.id],
          lang: locale.id,
        })),
        {locale: languageMapping[locale.id], lang: locale.id},
      ];
    }
  }, [translated]);

  relatedPost = getRelatedPosts();

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href={canonical} />
        <script>{databaseIdGlobal}</script>
      </Helmet>
      <SEO
        seo={seo}
        lang={language}
        locales={locales}
        translated={translated}
      />

      <div className={`${blogType} general_wrap blog_wrap id_${databaseId}`}>
        <section id="article_content">
          <div className="title">
            <div className="header_title">
              {category && category.length > 0 && (
                <div className="category-container">
                  {categories.map((item, index) => (
                    <p
                      key={`${index}-${item}`}
                      className="category_head dark_violet"
                    >
                      <Trans>
                        <a href={categoryUri[index]}>{item}</a>
                      </Trans>
                      {index < category.length - 1 ? ', ' : ''}
                    </p>
                  ))}
                </div>
              )}
              <h1 className="dacast dacast-medium">{getTitle()}</h1>
            </div>
            <div className="outer_desktop_banner clearfix">
              <div className="head_banner">
                <div className="blog_details_wrap">
                  <div className="head_wrapper">
                    <div className="tag_lines">
                      <p className="author">
                        <Trans>By</Trans> {authorName}
                        <span className="vl"></span>
                      </p>
                      <p className="dates">
                        <time
                          className="published"
                          itemProp="datePublished"
                          dateTime={publishedDateRaw}
                        >
                          {publishedDate}
                        </time>
                        <time
                          className="modified"
                          itemProp="dateModified"
                          dateTime={modifiedDateRaw}
                        >
                          {modifiedDate}
                        </time>
                        <span className="vl"></span>{' '}
                      </p>
                      <p className="read_time">
                        <span>
                          <Trans i18nKey="Min Read">
                            {{readTime}} Min Read
                          </Trans>
                        </span>
                      </p>
                    </div>
                    <div className="socials">
                      <a href={facebook}>
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="24"
                            cy="24"
                            r="23.5"
                            fill="white"
                            stroke="#707A8A"
                          />
                          <path
                            d="M30.0335 24.9393H25.751V40.6285H19.2626V24.9393H16.1768V19.4256H19.2626V15.8575C19.2626 13.306 20.4747 9.31055 25.8088 9.31055L30.6149 9.33065V14.6827H27.1277C26.5557 14.6827 25.7514 14.9685 25.7514 16.1857V19.4307H30.6004L30.0335 24.9393Z"
                            fill="#707A8A"
                          />
                        </svg>
                      </a>
                      <a href={linkedIn}>
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0)">
                            <circle
                              cx="24"
                              cy="24"
                              r="23.5"
                              fill="white"
                              stroke="#707A8A"
                            />
                            <path
                              d="M38.3398 25.9302V35.8249H32.6031V26.5933C32.6031 24.2754 31.7748 22.6924 29.6978 22.6924C28.1127 22.6924 27.171 23.7581 26.7552 24.79C26.6042 25.1588 26.5652 25.6709 26.5652 26.1882V35.8244H20.8281C20.8281 35.8244 20.9051 20.1892 20.8281 18.5708H26.5657V21.0158C26.5541 21.035 26.5379 21.0539 26.5276 21.0723H26.5657V21.0158C27.3281 19.8427 28.6877 18.1656 31.7359 18.1656C35.5102 18.1656 38.3398 20.6316 38.3398 25.9302ZM14.8266 10.2539C12.8642 10.2539 11.5803 11.5421 11.5803 13.2345C11.5803 14.8911 12.827 16.2165 14.7513 16.2165H14.7886C16.7895 16.2165 18.0336 14.8911 18.0336 13.2345C17.9955 11.5421 16.7895 10.2539 14.8266 10.2539ZM11.9213 35.8249H17.6563V18.5708H11.9213V35.8249Z"
                              fill="#707A8A"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="48" height="48" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                      <a href={twitter}>
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0)">
                            <circle
                              cx="24"
                              cy="24"
                              r="23.5"
                              fill="white"
                              stroke="#707A8A"
                            />
                            <path
                              d="M38.7009 17.2487C37.6724 17.7048 36.5661 18.0132 35.4058 18.151C36.5905 17.4412 37.4996 16.3182 37.9287 14.9779C36.8202 15.6354 35.5919 16.1124 34.2858 16.3696C33.2394 15.2551 31.7484 14.5586 30.0975 14.5586C26.9299 14.5586 24.3608 17.1276 24.3608 20.2952C24.3608 20.7449 24.4117 21.1825 24.5101 21.6026C19.7425 21.3635 15.5152 19.0798 12.6856 15.608C12.1919 16.4551 11.9087 17.4412 11.9087 18.4924C11.9087 20.4822 12.9222 22.2388 14.4607 23.2673C13.5208 23.2378 12.6356 22.9798 11.8625 22.549C11.8621 22.5734 11.8621 22.5977 11.8621 22.6217C11.8621 25.4012 13.8403 27.7196 16.4641 28.2462C15.9833 28.378 15.4755 28.4477 14.9531 28.4477C14.5826 28.4477 14.2237 28.4122 13.8737 28.3455C14.6036 30.6241 16.7217 32.2827 19.2326 32.3293C17.2689 33.8682 14.7957 34.785 12.1072 34.785C11.6452 34.785 11.1874 34.7581 10.7395 34.7046C13.2769 36.3329 16.293 37.2822 19.5325 37.2822C30.0842 37.2822 35.8546 28.541 35.8546 20.9596C35.8546 20.7111 35.849 20.4634 35.8379 20.2178C36.9597 19.4092 37.9321 18.3991 38.7009 17.2487Z"
                              fill="#707A8A"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="48" height="48" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                      <a href={youtube}>
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="24"
                            cy="24"
                            r="23.5"
                            fill="white"
                            stroke="#707A8A"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.4542 33.546C12.439 31.5307 12.439 28.2544 14.4542 26.2392L19.1683 21.5251L16.9291 19.286L12.2151 24C8.96238 27.2527 8.96238 32.5324 12.2151 35.7851C15.4678 39.0378 20.7475 39.0378 24.0002 35.7851L28.7142 31.0711L26.4751 28.8319L21.761 33.546C19.7458 35.5612 16.4695 35.5612 14.4542 33.546ZM20.4646 29.8926L29.8927 20.4645L27.5357 18.1075L18.1076 27.5356L20.4646 29.8926ZM19.2861 16.929L24.0002 12.2149C27.2529 8.96222 32.5326 8.96222 35.7853 12.2149C39.038 15.4676 39.038 20.7473 35.7853 24L31.0712 28.7141L28.8321 26.4749L33.5461 21.7609C35.5614 19.7456 35.5614 16.4693 33.5461 14.4541C31.5309 12.4388 28.2546 12.4388 26.2394 14.4541L21.5253 19.1681L19.2861 16.929Z"
                            fill="#707A8A"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                {featuredImage &&
                  featuredImage.node &&
                  featuredImage.node.localFile && (
                    <GatsbyImage
                      image={
                        featuredImage.node.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      alt="banner image featured"
                      loading="eager"
                    />
                  )}
              </div>
              <div id="head_author" className="desktop">
                <div className="author_card">
                  <div className="avatar">
                    <img
                      src={authorAvatar}
                      alt="author avatar"
                      height="65"
                      width="65"
                    />
                  </div>
                  <div className="information">
                    <h4 className="name dacast dacast-medium">{authorName}</h4>
                    <p className="description">{getDescription}</p>
                    <div className="socials">
                      <a href={linkedIn}>
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0)">
                            <path
                              d="M38.3398 25.9307V35.8254H32.6031V26.5938C32.6031 24.2759 31.7748 22.6929 29.6978 22.6929C28.1127 22.6929 27.171 23.7586 26.7552 24.7905C26.6042 25.1593 26.5652 25.6714 26.5652 26.1887V35.8249H20.8281C20.8281 35.8249 20.9051 20.1897 20.8281 18.5713H26.5657V21.0163C26.5541 21.0355 26.5379 21.0544 26.5276 21.0727H26.5657V21.0163C27.3281 19.8432 28.6877 18.1661 31.7359 18.1661C35.5102 18.1661 38.3398 20.6321 38.3398 25.9307ZM14.8266 10.2544C12.8642 10.2544 11.5803 11.5426 11.5803 13.235C11.5803 14.8916 12.827 16.217 14.7513 16.217H14.7886C16.7895 16.217 18.0336 14.8916 18.0336 13.235C17.9955 11.5426 16.7895 10.2544 14.8266 10.2544ZM11.9213 35.8254H17.6563V18.5713H11.9213V35.8254Z"
                              fill="#707A8A"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="48" height="48" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                      <a href={facebook}>
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M30.0335 24.9393H25.751V40.6285H19.2626V24.9393H16.1768V19.4256H19.2626V15.8575C19.2626 13.306 20.4747 9.31055 25.8088 9.31055L30.6149 9.33065V14.6827H27.1277C26.5557 14.6827 25.7514 14.9685 25.7514 16.1857V19.4307H30.6004L30.0335 24.9393Z"
                            fill="#707A8A"
                          />
                        </svg>
                      </a>
                      <a href={twitter}>
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0)">
                            <path
                              d="M38.7009 17.2482C37.6724 17.7043 36.5661 18.0127 35.4058 18.1505C36.5905 17.4407 37.4996 16.3177 37.9287 14.9774C36.8202 15.6349 35.5919 16.1119 34.2858 16.3691C33.2394 15.2546 31.7484 14.5581 30.0975 14.5581C26.9299 14.5581 24.3608 17.1272 24.3608 20.2947C24.3608 20.7444 24.4117 21.182 24.5101 21.6021C19.7425 21.363 15.5152 19.0793 12.6856 15.6075C12.1919 16.4546 11.9087 17.4407 11.9087 18.4919C11.9087 20.4817 12.9222 22.2383 14.4607 23.2668C13.5208 23.2373 12.6356 22.9793 11.8625 22.5485C11.8621 22.5729 11.8621 22.5973 11.8621 22.6212C11.8621 25.4007 13.8403 27.7191 16.4641 28.2457C15.9833 28.3775 15.4755 28.4472 14.9531 28.4472C14.5826 28.4472 14.2237 28.4117 13.8737 28.345C14.6036 30.6236 16.7217 32.2822 19.2326 32.3288C17.2689 33.8677 14.7957 34.7845 12.1072 34.7845C11.6452 34.7845 11.1874 34.7576 10.7395 34.7041C13.2769 36.3324 16.293 37.2817 19.5325 37.2817C30.0842 37.2817 35.8546 28.5405 35.8546 20.9591C35.8546 20.7106 35.849 20.4629 35.8379 20.2173C36.9597 19.4087 37.9321 18.3986 38.7009 17.2482Z"
                              fill="#707A8A"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="48" height="48" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                      <a href={youtube}>
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0)">
                            <path
                              d="M 22.5 32.25 V 18.75 L 31.5 25.5 M 37.5 14.1 C 36.6 13.8 31.05 13.5 25.5 13.5 C 19.95 13.5 14.4 13.785 13.5 14.07 C 11.16 14.85 10.5 20.1 10.5 25.5 C 10.5 30.885 11.16 36.15 13.5 36.915 C 14.4 37.215 19.95 37.5 25.5 37.5 C 31.05 37.5 36.6 37.215 37.5 36.915 C 39.84 36.15 40.5 30.885 40.5 25.5 C 40.5 20.1 39.84 14.865 37.5 14.1 Z"
                              fill="#707A8A"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect
                                width="40"
                                height="40"
                                fill="white"
                                transform="translate(4 4)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sticky_wrap">
            <div className="side_navigation desktop">
              <div className="table_content desktop">
                <div className="sticky-wrap">
                  <ul className="contents_list">
                    {tableOfContent.map((content, i) => {
                      return (
                        <li key={`${i}-${content}`}>
                          <a>{content}</a>
                        </li>
                      );
                    })}
                  </ul>
                  <h3 className="dacast dacast-medium">
                    <Trans>Share this post</Trans>
                  </h3>
                  <div className="socials side_nav">
                    <FacebookShareButton url={canonical} color={'#707A8A'}>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="24"
                          cy="24"
                          r="23.5"
                          fill="white"
                          stroke="#707A8A"
                        />
                        <path
                          d="M30.0335 24.9393H25.751V40.6285H19.2626V24.9393H16.1768V19.4256H19.2626V15.8575C19.2626 13.306 20.4747 9.31055 25.8088 9.31055L30.6149 9.33065V14.6827H27.1277C26.5557 14.6827 25.7514 14.9685 25.7514 16.1857V19.4307H30.6004L30.0335 24.9393Z"
                          fill="#707A8A"
                        />
                      </svg>
                    </FacebookShareButton>
                    <LinkedinShareButton url={canonical} color={'#707A8A'}>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <circle
                            cx="24"
                            cy="24"
                            r="23.5"
                            fill="white"
                            stroke="#707A8A"
                          />
                          <path
                            d="M38.3398 25.9302V35.8249H32.6031V26.5933C32.6031 24.2754 31.7748 22.6924 29.6978 22.6924C28.1127 22.6924 27.171 23.7581 26.7552 24.79C26.6042 25.1588 26.5652 25.6709 26.5652 26.1882V35.8244H20.8281C20.8281 35.8244 20.9051 20.1892 20.8281 18.5708H26.5657V21.0158C26.5541 21.035 26.5379 21.0539 26.5276 21.0723H26.5657V21.0158C27.3281 19.8427 28.6877 18.1656 31.7359 18.1656C35.5102 18.1656 38.3398 20.6316 38.3398 25.9302ZM14.8266 10.2539C12.8642 10.2539 11.5803 11.5421 11.5803 13.2345C11.5803 14.8911 12.827 16.2165 14.7513 16.2165H14.7886C16.7895 16.2165 18.0336 14.8911 18.0336 13.2345C17.9955 11.5421 16.7895 10.2539 14.8266 10.2539ZM11.9213 35.8249H17.6563V18.5708H11.9213V35.8249Z"
                            fill="#707A8A"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="48" height="48" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </LinkedinShareButton>
                    <TwitterShareButton url={canonical} color={'#707A8A'}>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <circle
                            cx="24"
                            cy="24"
                            r="23.5"
                            fill="white"
                            stroke="#707A8A"
                          />
                          <path
                            d="M38.7009 17.2487C37.6724 17.7048 36.5661 18.0132 35.4058 18.151C36.5905 17.4412 37.4996 16.3182 37.9287 14.9779C36.8202 15.6354 35.5919 16.1124 34.2858 16.3696C33.2394 15.2551 31.7484 14.5586 30.0975 14.5586C26.9299 14.5586 24.3608 17.1276 24.3608 20.2952C24.3608 20.7449 24.4117 21.1825 24.5101 21.6026C19.7425 21.3635 15.5152 19.0798 12.6856 15.608C12.1919 16.4551 11.9087 17.4412 11.9087 18.4924C11.9087 20.4822 12.9222 22.2388 14.4607 23.2673C13.5208 23.2378 12.6356 22.9798 11.8625 22.549C11.8621 22.5734 11.8621 22.5977 11.8621 22.6217C11.8621 25.4012 13.8403 27.7196 16.4641 28.2462C15.9833 28.378 15.4755 28.4477 14.9531 28.4477C14.5826 28.4477 14.2237 28.4122 13.8737 28.3455C14.6036 30.6241 16.7217 32.2827 19.2326 32.3293C17.2689 33.8682 14.7957 34.785 12.1072 34.785C11.6452 34.785 11.1874 34.7581 10.7395 34.7046C13.2769 36.3329 16.293 37.2822 19.5325 37.2822C30.0842 37.2822 35.8546 28.541 35.8546 20.9596C35.8546 20.7111 35.849 20.4634 35.8379 20.2178C36.9597 19.4092 37.9321 18.3991 38.7009 17.2487Z"
                            fill="#707A8A"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="48" height="48" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </TwitterShareButton>
                    <EmailShareButton
                      color={'#707A8A'}
                      border={2}
                      url={canonical}
                    >
                      <EmailIcon
                        iconFillColor="#707A8A"
                        bgStyle={{fill: 'white'}}
                        style={{
                          border: '1px solid #707A8A',
                          borderRadius: '100%',
                        }}
                      />
                    </EmailShareButton>
                  </div>
                  <div className="free_trial free_trial--top">
                    <h3 className="dacast dacast-medium">
                      <Trans>Sign up for a 14-Day trial.</Trans>
                    </h3>
                    <a href="https://www.dacast.com/signup/">
                      <button className="dacast-btn dacast-btn--primary">
                        <Trans>Get Started!</Trans>
                      </button>
                    </a>
                    <ul>
                      <li>Start streaming in minutes</li>
                      <li>10 GB of free bandwidth on us</li>
                      <li>No credit card required</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper_desktop">
              <div className="table_content mobile">
                <ul className="contents_list"></ul>
              </div>
              <div className="blog_content">
                {ParseHTML(getContent(), language)}
              </div>
              <div className="author_card author_bottom">
                <div className="avatar">
                  <img
                    src={authorAvatar}
                    alt="author avatar"
                    height="65"
                    width="65"
                  />
                </div>
                <div className="information">
                  <h4 className="name dacast dacast-medium">{authorName}</h4>
                  <p className="description">{getDescription}</p>
                  <div className="socials bottom">
                    <a href={linkedIn}>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <path
                            d="M38.3398 25.9307V35.8254H32.6031V26.5938C32.6031 24.2759 31.7748 22.6929 29.6978 22.6929C28.1127 22.6929 27.171 23.7586 26.7552 24.7905C26.6042 25.1593 26.5652 25.6714 26.5652 26.1887V35.8249H20.8281C20.8281 35.8249 20.9051 20.1897 20.8281 18.5713H26.5657V21.0163C26.5541 21.0355 26.5379 21.0544 26.5276 21.0727H26.5657V21.0163C27.3281 19.8432 28.6877 18.1661 31.7359 18.1661C35.5102 18.1661 38.3398 20.6321 38.3398 25.9307ZM14.8266 10.2544C12.8642 10.2544 11.5803 11.5426 11.5803 13.235C11.5803 14.8916 12.827 16.217 14.7513 16.217H14.7886C16.7895 16.217 18.0336 14.8916 18.0336 13.235C17.9955 11.5426 16.7895 10.2544 14.8266 10.2544ZM11.9213 35.8254H17.6563V18.5713H11.9213V35.8254Z"
                            fill="#707A8A"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="48" height="48" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                    <a href={facebook}>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30.0335 24.9393H25.751V40.6285H19.2626V24.9393H16.1768V19.4256H19.2626V15.8575C19.2626 13.306 20.4747 9.31055 25.8088 9.31055L30.6149 9.33065V14.6827H27.1277C26.5557 14.6827 25.7514 14.9685 25.7514 16.1857V19.4307H30.6004L30.0335 24.9393Z"
                          fill="#707A8A"
                        />
                      </svg>
                    </a>
                    <a href={twitter}>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <path
                            d="M38.7009 17.2482C37.6724 17.7043 36.5661 18.0127 35.4058 18.1505C36.5905 17.4407 37.4996 16.3177 37.9287 14.9774C36.8202 15.6349 35.5919 16.1119 34.2858 16.3691C33.2394 15.2546 31.7484 14.5581 30.0975 14.5581C26.9299 14.5581 24.3608 17.1272 24.3608 20.2947C24.3608 20.7444 24.4117 21.182 24.5101 21.6021C19.7425 21.363 15.5152 19.0793 12.6856 15.6075C12.1919 16.4546 11.9087 17.4407 11.9087 18.4919C11.9087 20.4817 12.9222 22.2383 14.4607 23.2668C13.5208 23.2373 12.6356 22.9793 11.8625 22.5485C11.8621 22.5729 11.8621 22.5973 11.8621 22.6212C11.8621 25.4007 13.8403 27.7191 16.4641 28.2457C15.9833 28.3775 15.4755 28.4472 14.9531 28.4472C14.5826 28.4472 14.2237 28.4117 13.8737 28.345C14.6036 30.6236 16.7217 32.2822 19.2326 32.3288C17.2689 33.8677 14.7957 34.7845 12.1072 34.7845C11.6452 34.7845 11.1874 34.7576 10.7395 34.7041C13.2769 36.3324 16.293 37.2817 19.5325 37.2817C30.0842 37.2817 35.8546 28.5405 35.8546 20.9591C35.8546 20.7106 35.849 20.4629 35.8379 20.2173C36.9597 19.4087 37.9321 18.3986 38.7009 17.2482Z"
                            fill="#707A8A"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="48" height="48" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                    <a href={youtube}>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <path
                            d="M 22.5 32.25 V 18.75 L 31.5 25.5 M 37.5 14.1 C 36.6 13.8 31.05 13.5 25.5 13.5 C 19.95 13.5 14.4 13.785 13.5 14.07 C 11.16 14.85 10.5 20.1 10.5 25.5 C 10.5 30.885 11.16 36.15 13.5 36.915 C 14.4 37.215 19.95 37.5 25.5 37.5 C 31.05 37.5 36.6 37.215 37.5 36.915 C 39.84 36.15 40.5 30.885 40.5 25.5 C 40.5 20.1 39.84 14.865 37.5 14.1 Z"
                            fill="#707A8A"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect
                              width="40"
                              height="40"
                              fill="white"
                              transform="translate(4 4)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="free_trial_nav">
              <div className="sticky-wrap">
                <div className="free_trial free_trial--top">
                  <h3 className="dacast dacast-medium">
                    <Trans>Free 14-Day Trial</Trans>
                  </h3>
                  <a href="https://www.dacast.com/signup/">
                    <button className="dacast-btn dacast-btn--primary">
                      <Trans>Get Started!</Trans>
                    </button>
                  </a>
                  <ul>
                    <li>Start streaming immediately</li>
                    <li>No credit card required</li>
                    <li>10 GB of bandwidth</li>
                  </ul>
                </div>
                <h5 className="dacast dacast-bold">
                  <Trans>Read Next</Trans>
                </h5>
                <div className="related_articles">
                  {relatedPost.length > 0 && (
                    <Related
                      post={relatedPost[0]}
                      classNames={'read_next_card_side'}
                    />
                  )}
                  {relatedPost.length > 1 && (
                    <Related
                      post={relatedPost[1]}
                      classNames={'read_next_card_side'}
                    />
                  )}
                  {relatedPost.length > 2 && (
                    <Related
                      post={relatedPost[2]}
                      classNames={'read_next_card_side'}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="comments"></section>

        {relatedPost && relatedPost.length > 0 && (
          <section id="read_next">
            <h2 className="title dacast dacast-medium">
              <Trans>Read Next</Trans>
            </h2>
            <div className="blog_cards_container">
              {relatedPost.length > 0 && <Related post={relatedPost[0]} />}
              {relatedPost.length > 1 && <Related post={relatedPost[1]} />}
              {relatedPost.length > 2 && <Related post={relatedPost[2]} />}
            </div>

            <div className="categories_links">
              <h3 className="dacast dacast-medium">
                <Trans>Categories</Trans>
              </h3>
              <ul>
                <li>
                  <a href="https://www.dacast.com/latest-news/">
                    <Trans>Dacast News</Trans>
                  </a>
                </li>
                <li>
                  <a href="https://www.dacast.com/case-studies/">
                    <Trans>Get Inspired</Trans>
                  </a>
                </li>
                <li>
                  <a href="https://www.dacast.com/press/">
                    <Trans>Marketing News</Trans>
                  </a>
                </li>
                <li>
                  <a href="https://www.dacast.com/category/blog/">
                    <Trans>Product Updates</Trans>
                  </a>
                </li>
                <li>
                  <a href="https://www.dacast.com/case-studies/">
                    <Trans>Video for Business</Trans>
                  </a>
                </li>
              </ul>
            </div>
          </section>
        )}
      </div>
      <section id="subscribe">
        <SubsCard
          type="blue"
          backdrop="https://www.dacast.com/wp-content/uploads/2022/09/blue-1.svg"
        />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query PostQuery($databaseId: Int, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    post: allWpPost(filter: {databaseId: {eq: $databaseId}}) {
      edges {
        node {
          id
          databaseId
          title
          slug
          guid
          uri
          slug
          link
          tableOfContent
          translated {
            id
            link
            content
            title
            translatedCategoryName
            translatedCategoryUri
            seo {
              metaRobotsNofollow
              metaRobotsNoindex
              breadcrumbs {
                text
                url
              }
              canonical
              cornerstone
              focuskw
              metaDesc
              metaKeywords
              opengraphAuthor
              opengraphDescription
              opengraphModifiedTime
              opengraphPublishedTime
              opengraphPublisher
              opengraphTitle
              opengraphSiteName
              opengraphType
              opengraphUrl
              title
              twitterDescription
              twitterTitle
              schema {
                articleType
                pageType
                raw
              }
            }
            locale {
              locale
              id
            }
            relatedPost {
              databaseId
              uri
              title
              dateGmt
              modifiedGmt
              content
              featuredImage {
                node {
                  srcSet
                  sizes
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.77777777778
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        breakpoints: [990, 660, 520, 400]
                      )
                    }
                  }
                }
              }
              locale {
                locale
                id
              }
              author {
                node {
                  url
                  slug
                  name
                  description
                  email
                  seo {
                    social {
                      facebook
                      instagram
                      linkedIn
                      twitter
                    }
                  }
                  avatar {
                    url
                    foundAvatar
                  }
                }
              }
            }
            relatedFaq {
              databaseId
              uri
              title
              dateGmt
              modifiedGmt
              content
              locale {
                locale
                id
              }
              author {
                node {
                  url
                  slug
                  name
                  description
                  email
                  seo {
                    social {
                      facebook
                      instagram
                      linkedIn
                      twitter
                    }
                  }
                  avatar {
                    url
                    foundAvatar
                  }
                }
              }
            }
            relatedArticle {
              databaseId
              uri
              title
              dateGmt
              modifiedGmt
              content
              featuredImage {
                node {
                  srcSet
                  sizes
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.77777777778
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        breakpoints: [990, 660, 520, 400]
                      )
                    }
                  }
                }
              }
              locale {
                locale
                id
              }
              author {
                node {
                  url
                  slug
                  name
                  description
                  email
                  seo {
                    social {
                      facebook
                      instagram
                      linkedIn
                      twitter
                    }
                  }
                  avatar {
                    url
                    foundAvatar
                  }
                }
              }
            }
          }
          locale {
            locale
            id
          }
          translatedCategoryName
          translatedCategoryUri
          dateGmt
          modifiedGmt
          seo {
            metaRobotsNofollow
            metaRobotsNoindex
            breadcrumbs {
              text
              url
            }
            canonical
            cornerstone
            focuskw
            metaDesc
            metaKeywords
            opengraphAuthor
            opengraphDescription
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphTitle
            opengraphSiteName
            opengraphType
            opengraphUrl
            title
            twitterDescription
            twitterTitle
            schema {
              articleType
              pageType
              raw
            }
          }
          author {
            node {
              url
              slug
              name
              description
              descriptionFr
              descriptionEs
              descriptionIt
              descriptionPt
              email
              seo {
                social {
                  facebook
                  instagram
                  linkedIn
                  twitter
                }
              }
              avatar {
                url
                foundAvatar
              }
            }
          }
          commentCount
          comments {
            nodes {
              author {
                node {
                  email
                  name
                  url
                }
              }
              content
              dateGmt
              databaseId
              parentDatabaseId
            }
          }
          internal {
            type
          }
          content
          featuredImage {
            node {
              srcSet
              sizes
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.77777777778
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    breakpoints: [990, 660, 520, 400]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
              uri
              slug
            }
          }
          relatedPost {
            databaseId
            uri
            title
            dateGmt
            modifiedGmt
            author {
              node {
                url
                slug
                name
                description
                email
                seo {
                  social {
                    facebook
                    instagram
                    linkedIn
                    twitter
                  }
                }
                avatar {
                  url
                  foundAvatar
                }
              }
            }
            locale {
              locale
            }
            content
            featuredImage {
              node {
                srcSet
                sizes
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      aspectRatio: 1.77777777778
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      breakpoints: [990, 660, 520, 400]
                    )
                  }
                }
              }
            }
            categories {
              nodes {
                uri
                name
              }
            }
          }
        }
      }
    }
    article: allWpArticle(filter: {databaseId: {eq: $databaseId}}) {
      edges {
        node {
          id
          databaseId
          title
          slug
          guid
          uri
          slug
          link
          tableOfContent
          translatedCategoryName
          translatedCategoryUri
          locale {
            locale
            id
          }
          dateGmt
          modifiedGmt
          seo {
            metaRobotsNofollow
            metaRobotsNoindex
            breadcrumbs {
              text
              url
            }
            canonical
            cornerstone
            focuskw
            metaDesc
            metaKeywords
            opengraphAuthor
            opengraphDescription
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphTitle
            opengraphSiteName
            opengraphType
            opengraphUrl
            title
            twitterDescription
            twitterTitle
            schema {
              articleType
              pageType
              raw
            }
          }
          author {
            node {
              url
              slug
              name
              description
              descriptionFr
              descriptionEs
              descriptionIt
              descriptionPt
              email
              seo {
                social {
                  facebook
                  instagram
                  linkedIn
                  twitter
                }
              }
              avatar {
                url
                foundAvatar
              }
            }
          }
          commentCount
          comments {
            nodes {
              author {
                node {
                  email
                  name
                  url
                }
              }
              content
              dateGmt
              databaseId
              parentDatabaseId
            }
          }
          internal {
            type
          }
          content
          translated {
            id
            link
            content
            title
            translatedCategoryName
            translatedCategoryUri
            seo {
              metaRobotsNofollow
              metaRobotsNoindex
              breadcrumbs {
                text
                url
              }
              canonical
              cornerstone
              focuskw
              metaDesc
              metaKeywords
              opengraphAuthor
              opengraphDescription
              opengraphModifiedTime
              opengraphPublishedTime
              opengraphPublisher
              opengraphTitle
              opengraphSiteName
              opengraphType
              opengraphUrl
              title
              twitterDescription
              twitterTitle
              schema {
                articleType
                pageType
                raw
              }
            }
            locale {
              locale
              id
            }
            relatedPost {
              databaseId
              uri
              title
              dateGmt
              modifiedGmt
              content
              featuredImage {
                node {
                  srcSet
                  sizes
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.77777777778
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        breakpoints: [990, 660, 520, 400]
                      )
                    }
                  }
                }
              }
              locale {
                locale
                id
              }
              author {
                node {
                  url
                  slug
                  name
                  description
                  email
                  seo {
                    social {
                      facebook
                      instagram
                      linkedIn
                      twitter
                    }
                  }
                  avatar {
                    url
                    foundAvatar
                  }
                }
              }
            }
            relatedFaq {
              databaseId
              uri
              title
              dateGmt
              modifiedGmt
              content
              locale {
                locale
                id
              }
              author {
                node {
                  url
                  slug
                  name
                  description
                  email
                  seo {
                    social {
                      facebook
                      instagram
                      linkedIn
                      twitter
                    }
                  }
                  avatar {
                    url
                    foundAvatar
                  }
                }
              }
            }
            relatedArticle {
              databaseId
              uri
              title
              dateGmt
              modifiedGmt
              content
              featuredImage {
                node {
                  srcSet
                  sizes
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.77777777778
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        breakpoints: [990, 660, 520, 400]
                      )
                    }
                  }
                }
              }
              locale {
                locale
                id
              }
              author {
                node {
                  url
                  slug
                  name
                  description
                  email
                  seo {
                    social {
                      facebook
                      instagram
                      linkedIn
                      twitter
                    }
                  }
                  avatar {
                    url
                    foundAvatar
                  }
                }
              }
            }
            articleCategories {
              nodes {
                name
              }
            }
          }
          featuredImage {
            node {
              srcSet
              sizes
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.77777777778
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    breakpoints: [990, 660, 520, 400]
                  )
                }
              }
            }
          }
          articleCategories {
            nodes {
              name
              uri
              slug
            }
          }
          relatedArticle {
            databaseId
            uri
            title
            dateGmt
            modifiedGmt
            author {
              node {
                url
                slug
                name
                description
                email
                seo {
                  social {
                    facebook
                    instagram
                    linkedIn
                    twitter
                  }
                }
                avatar {
                  url
                  foundAvatar
                }
              }
            }
            locale {
              locale
            }
            content
            featuredImage {
              node {
                srcSet
                sizes
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      aspectRatio: 1.77777777778
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      breakpoints: [990, 660, 520, 400]
                    )
                  }
                }
              }
            }
            articleCategories {
              nodes {
                uri
                name
              }
            }
          }
        }
      }
    }
    faq: allWpFaq(filter: {databaseId: {eq: $databaseId}}) {
      edges {
        node {
          id
          databaseId
          title
          slug
          guid
          uri
          slug
          link
          tableOfContent
          translatedCategoryName
          translatedCategoryUri
          locale {
            locale
            id
          }
          dateGmt
          modifiedGmt
          seo {
            metaRobotsNofollow
            metaRobotsNoindex
            breadcrumbs {
              text
              url
            }
            canonical
            cornerstone
            focuskw
            metaDesc
            metaKeywords
            opengraphAuthor
            opengraphDescription
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphTitle
            opengraphSiteName
            opengraphType
            opengraphUrl
            title
            twitterDescription
            twitterTitle
            schema {
              articleType
              pageType
              raw
            }
          }
          author {
            node {
              url
              slug
              name
              description
              descriptionFr
              descriptionEs
              descriptionIt
              descriptionPt
              email
              seo {
                social {
                  facebook
                  instagram
                  linkedIn
                  twitter
                }
              }
              avatar {
                url
                foundAvatar
              }
            }
          }
          commentCount
          comments {
            nodes {
              author {
                node {
                  email
                  name
                  url
                }
              }
              content
              dateGmt
              databaseId
              parentDatabaseId
            }
          }
          internal {
            type
          }
          content
          translated {
            id
            link
            content
            title
            translatedCategoryName
            translatedCategoryUri
            seo {
              metaRobotsNofollow
              metaRobotsNoindex
              breadcrumbs {
                text
                url
              }
              canonical
              cornerstone
              focuskw
              metaDesc
              metaKeywords
              opengraphAuthor
              opengraphDescription
              opengraphModifiedTime
              opengraphPublishedTime
              opengraphPublisher
              opengraphTitle
              opengraphSiteName
              opengraphType
              opengraphUrl
              title
              twitterDescription
              twitterTitle
              schema {
                articleType
                pageType
                raw
              }
            }
            locale {
              locale
              id
            }
            relatedPost {
              databaseId
              uri
              title
              dateGmt
              modifiedGmt
              content
              featuredImage {
                node {
                  srcSet
                  sizes
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.77777777778
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        breakpoints: [990, 660, 520, 400]
                      )
                    }
                  }
                }
              }
              locale {
                locale
                id
              }
              author {
                node {
                  url
                  slug
                  name
                  description
                  email
                  seo {
                    social {
                      facebook
                      instagram
                      linkedIn
                      twitter
                    }
                  }
                  avatar {
                    url
                    foundAvatar
                  }
                }
              }
            }
            relatedFaq {
              databaseId
              uri
              title
              dateGmt
              modifiedGmt
              content
              locale {
                locale
                id
              }
              author {
                node {
                  url
                  slug
                  name
                  description
                  email
                  seo {
                    social {
                      facebook
                      instagram
                      linkedIn
                      twitter
                    }
                  }
                  avatar {
                    url
                    foundAvatar
                  }
                }
              }
            }
            relatedArticle {
              databaseId
              uri
              title
              dateGmt
              modifiedGmt
              content
              featuredImage {
                node {
                  srcSet
                  sizes
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.77777777778
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        breakpoints: [990, 660, 520, 400]
                      )
                    }
                  }
                }
              }
              locale {
                locale
                id
              }
              author {
                node {
                  url
                  slug
                  name
                  description
                  email
                  seo {
                    social {
                      facebook
                      instagram
                      linkedIn
                      twitter
                    }
                  }
                  avatar {
                    url
                    foundAvatar
                  }
                }
              }
            }
          }
          faqCategories {
            nodes {
              name
              uri
              slug
            }
          }
          relatedFaq {
            databaseId
            uri
            title
            dateGmt
            modifiedGmt
            author {
              node {
                url
                slug
                name
                description
                email
                seo {
                  social {
                    facebook
                    instagram
                    linkedIn
                    twitter
                  }
                }
                avatar {
                  url
                  foundAvatar
                }
              }
            }
            locale {
              locale
            }
            content
            faqCategories {
              nodes {
                uri
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default blogPage;
