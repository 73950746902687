import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import { Link as GatsbyLink } from 'gatsby';

const Related = ({ post, classNames = 'read_next_card' }) => {
  var readTime = Math.ceil(post.content.split(' ').length / 400);
  const locale =
    post && post.locale ? post.locale.locale.replace('_', '-') : 'en-us';
  const postDate = new Date(post.dateGmt).toLocaleDateString(locale, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
  const modDate = new Date(post.modifiedGmt).toLocaleDateString(locale, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  return (
    <>
      <div className={classNames}>
        {post.featuredImage &&
          post.featuredImage.node &&
          post.featuredImage.node.localFile ? (
          <GatsbyImage
            image={
              post.featuredImage.node.localFile.childImageSharp.gatsbyImageData
            }
            alt="article featured"
            loading="eager"
          />
        ) : (
          <img
            src="https://www.dacast.com/wp-content/uploads/2021/03/Dacast-streaming-platform.png"
            alt="article featured"
            className="read-next-placeholder"
          />
        )}
        <div className="card_info_wrap">
          {post.articleCategories &&
            post.articleCategories.length > 0 &&
            post.articleCategories.map(({ uri, name }, index) => (
              <p className="category_head dark_violet" key={index}>
                <GatsbyLink to={uri}>{name}</GatsbyLink>
              </p>
            ))}
          {post.title && (
            <GatsbyLink to={post.uri}>
              <h2>{post.title}</h2>
            </GatsbyLink>
          )}
          <div className="author_card">
            {post.author.node &&
              post.author.node.avatar &&
              post.author.node.avatar.foundAvatar && (
                <div className="avatar">
                  <img
                    src={post.author.node.avatar.url}
                    alt="author avatar"
                    height="40"
                    width="40"
                  />
                </div>
              )}

            <div className="information">
              {post.author.node && (
                <h4 className="name">{post.author.node.name}</h4>
              )}
              <p className="description">
                <time className="published" dateTime={post.dateGmt}>
                  {postDate}
                </time>
                <time className="modified" dateTime={post.modifiedGmt}>
                  {modDate}
                </time>
                <span className="vl"></span>
                <div className="time_read">
                  {(post.locale && post.locale.locale !== 'en_US') ? (
                    <span>
                      <Trans i18nKey="Min Read">{{readTime}} Min Read</Trans>
                    </span>
                  ) : (
                    <span>{readTime} Min Read</span>
                  )}
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Related;

Related.propTypes = {
  post: PropTypes.object,
  classNames: PropTypes.string,
};
